<template>
	<div>
		<a-row :gutter="30">
		     <a-col  :span="12">
				 <ranking-take-count></ranking-take-count>
			 </a-col>
			 <a-col  :span="12">
				 <ranking-views></ranking-views>
			 </a-col>
		 </a-row>
	</div>
</template>

<script>
	import RankingTakeCount from './RankingTakeCount.vue';
	import RankingViews from './RankingViews.vue';
	export default{
		components:{
			RankingTakeCount,
			RankingViews
		},
		data(){
			return {
				
			}
		},
	}
</script>

<style>
	.ranking-box{
		width: 100%;
		min-height: 600px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 24px;
		line-height: 1;
	}
</style>