<template>
	<div>
		<div class="bg-w pd30" style="width: 100%;">
			<div class="flex alcenter">
				<i class="iconfont icondata10 ft32" style="color:#4772FF;"></i>
				<span class="ml15 ft18 ftw600 cl-black">最近15天订单趋势图表</span>
			</div>
			
			<div class="mt20">
				<div class="flex alcenter space">
					<div style="width: 850px; height: 350px;">
							<ve-line :data="chartLineData" height="350px" width="850px" :settings="chartLineSettings"></ve-line>
						</div>
						<div>
							<div class="visit-item" style="padding: 30px;">
								<div class="flex space alcenter">
									<div>订单数</div>
									<div class="visit-item-h" style="background-color: #1890FF;">
										<div class="visit-item-c5" style="background: rgba(24,144,255,0.5)"></div>
										<div class="visit-item-c1" style="background: rgba(24,144,255,1)"></div>
									</div>
								</div>
								<div class="mt16 ft26 ftw600" style="color:rgba(24,144,255,1);">{{order_total_data.total_order_num}}</div>
								<div class="flex mt20 alcenter">
									<div class="ft12 ftw400 cl-notice">昨日</div>
									<div class="ft16 ftw600 cl-main ml10">{{order_total_data.yesterday_order_num}}</div>
								</div>
							</div>
					
							<div class="visit-item mt20" style="padding: 30px;">
								<div class="flex space alcenter">
									<div>订单金额</div>
									<div class="visit-item-h" style="background-color: #00BD8A;">
										<div class="visit-item-c5" style="background: rgba(0,189,138,0.5);"></div>
										<div class="visit-item-c1" style="background: rgba(0,189,138,1);"></div>
									</div>
								</div>
								<div class="mt16 ft26 ftw600" style="color:rgba(0,189,138,1);">{{order_total_data.total_order_money}}</div>
								<div class="flex mt20 alcenter">
									<div class="ft12 ftw400 cl-notice">昨日</div>
									<div class="ft16 ftw600 cl-main ml10">{{order_total_data.yesterday_order_money}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			this.chartExtend = {
				legend: {
					show: false
				}, //隐藏legend
				series: {
					center: ['50%', '50%']
				}
			};
			this.chartLineSettings = {
				metrics: ['订单金额','订单数'],
				dimension: ['日期']
			};
			
			return{
				chartSettings: {
					radius: ['55%', '80%'],
					center: ['50%', '20%'],
					label: {
						normal: {
							show: false,
							position: 'center'
						},
						emphasis: {
							show: false,
							testStyle: {
								fontSize: '30',
								fontWeight: 'bold'
							}
						},
					},
				},
				ringChart: {
					colors: ["#5DBCFF", "#FFA100", "#00C657", "#5E40FF"]
				},
				chartLineData: {
					columns: ['日期', '订单数', '订单金额'],
					rows: []
				},
				
				order_total_data:{
					yesterday_order_num:0,
					yesterday_order_money:0,
					total_order_num:0,
					total_order_money:0,
				}
			}
			
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMallStatisticsTrend').then(res=>{
					this.chartLineData.rows=res.order_data;
					this.order_total_data=res.order_total_data;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			}
		}
	}
</script>

<style>
	.visit-item {
		width: 300px;
		height: 164px;
		background: rgba(255, 255, 255, 1);
		border-radius: 4px;
		border: 1px solid rgba(241, 244, 246, 1);
	}

	.visit-item-h {
		width: 30px;
		height: 1px;
	}

	.visit-item-c5 {
		width: 12px;
		height: 12px;
		z-index: 10;
		border-radius: 12px;
		position: relative;
		left: 9px;
		bottom: 6px;
	}

	.visit-item-c1 {
		width: 8px;
		height: 8px;
		z-index: 11;
		border-radius: 8px;
		position: relative;
		left: 11px;
		bottom: 16px;
	}
</style>
